import { SellZoneType } from '@/constants/sell-zone';
import { Product } from '@/models/api/Product';

export enum OrderStatus {
    Blocked = 'BLOCKED',
    Canceled = 'CANCELED',
    Created = 'CREATED',
    Confirmed = 'CONFIRMED',
    Executed = 'EXECUTED',
    Resent = 'RESENT',
    Returned = 'RETURNED',
    Sent = 'SENT',
    Suspended = 'SUSPENDED'
}

export enum OrderProductType {
    Present = 'PRESENT',
    Regular = 'REGULAR'
}

export enum DataSource {
    Forefront = 'FOREFRONT',
    Scrapped = 'SCRAPPED',
    System = 'SYSTEM'
}

export enum PaymentMethod {
    Cash = 'CASH',
    None = 'NONE',
    Transfer = 'TRANSFER'
}

export enum CheckoutStatusError {
    ShippingPaymentMethodNotAvailable = 'SHIPPING_METHOD_NOT_AVAILABLE',
    MissingAddressData = 'MISSING_ADDRESS_DATA',
    MissingAddressDataLocker = 'MISSING_ADDRESS_DATA_LOCKER'
}

export type CheckoutStatusErrorObject = {
    title: CheckoutStatusError;
    list?: string[];
};

export type OrderActivityMonth = {
    month: number;
    year: number;
};

export type OrderHistory = {
    activityMonths: OrderActivityMonth[];
    currencySymbol: string;
    orders: Order[];
    zoneFilter: boolean;
};

export type Order = {
    bonuses: number;
    dataSource: DataSource;
    factureNo: string;
    netVolume: number;
    orderNo: string;
    orderCreationDate: number;
    orderCreationDateString: string | null;
    orderGrossCost: number;
    sellZone: SellZoneType;
    status: OrderStatus;
    paymentMethod: PaymentMethod;
    paymentMethodFeeGrossCost: number;
    paymentUrl: string;
    payed: boolean;
    refunds: number | null;
    totalPoints: number;
    totalShippingFee: number;
    inpostLocker?: string;
};

export type OrderProduct = {
    amountBought: number;
    discountedTotalGrossPrice: number;
    discountedUnitGrossPrice: number;
    product: Product;
    totalGrossPrice: number;
    unitGrossPrice: number;
};

export type OrderShippingAddress = {
    apartmentNo: string;
    buildingNo: string;
    city: string;
    comment: string | null;
    companyName: string | null;
    country: string;
    district: string | null;
    email: string;
    fullName: string;
    mobile: string;
    province: string | null;
    street: string;
    zipCode: string;
};

export type OrderHistoryCorrectiveInvoice = {
    creationDate: string;
    correctFactureNo: string;
    grossCost: number;
    productCount: string;
};

export type OrderHistoryDetails = {
    bonuses: number;
    correctFactures: OrderHistoryCorrectiveInvoice[];
    currencySymbol: string;
    gifts: OrderProduct[];
    shippingProduct: OrderProduct;
    grossCostAfterDiscount: number;
    products: OrderProduct[];
    order: Order;
    refunds: number;
    shippingAddress: OrderShippingAddress;
    totalShippingFee: number;
};
